import {graphql} from 'gatsby'
import React from 'react'

import Layout from '../containers/layout'
import Projects from '../components/projects'

export const query = graphql`
  query VideosPageQuery {
    videos: allSanityVideo(
      sort: {fields: [publishedAt], order: DESC}
    ) {
      edges {
        node {
          id
          title
          video {
            url
          }
          _rawDescription
        }
      }
    }
  }
`

const VideosPage = ({ data: { videos } }) => (
  <Layout>
    <Projects projects={videos} />
  </Layout>
)

export default VideosPage
